<template>
  <div id="form">
    <!-- Form -->
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left">
      <!-- 排序 -->
      <el-form-item
        label="排序"
        prop="sort">
        <el-input max="100" v-model="query.sort" />
      </el-form-item>
      <!-- 问题 -->
      <el-form-item
        label="问题"
        prop="question">
        <el-input v-model="query.question" />
      </el-form-item>
      <!-- 答覆 -->
      <el-form-item
        label="答覆"
        prop="content">
        <tinymce-editor v-model="query.content" type="faq" :id="query.id" />
      </el-form-item>
    </el-form>
    <!-- Footer -->
    <div
      slot="footer"
      class="dialog-footer">
      <!-- 取消 -->
      <el-button @click="handleCancel">
        取消
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { postEditFAQ, getFAQDetail } from '@/api/admin/help'
import TinymceEditor from '@/components/admin/tinymce-editor.vue'
import { adminLogout } from '@/utils/admin'

// import resStatus from '@/constants/resStatus'
// import { categoryOption } from '@/constants/member'
// import EditPass from './EditPass'
// import { isMemberEditable } from '@/utils/admin'
// import { postEditUser, postEditPass } from '@/api/admin/member'
// import { arrCountryCode } from '@/constants/countryCode'

export default {
  components: {
    TinymceEditor
  },
  data () {
    return {
      formName: 'editForm',
      query: {
        id: '',
        sort: '',
        question: '',
        answer: 'answer',
        content: '',
        is_visible: ''
      },
      queryRule: {
        sort: [
          {
            required: true,
            message: '请输入排序數字',
            trigger: 'blur'
          }
        ],
        question: [
          {
            required: true,
            message: '请输入问题',
            tribber: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入答复内容',
            tribber: 'blur'
          }
        ]
      }
    }
  },
  computed: {},
  methods: {
    init () {
      getFAQDetail(this.query.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.query = res.data
            window.tinymce.editors[0].setContent(this.query.content)
            break
          default:
            adminLogout()
            this.$message.error(res.message)
        }
      }).catch(err => {
        adminLogout()
        this.$message.error(err)
      })
    },
    handleCancel () {
      // this.$refs[this.formName].resetFields()
      // this.dialogVisible = false
      this.$router.go(-1)
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid)
          this.editFAQ()
      })
    },
    editFAQ () {
      postEditFAQ(this.query.id, this.query)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              this.handleCancel()
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  },
  created () {
    this.query.id = this.$route.params.id
    this.init()
  },
  watch: {
    dialogData (value) {
      this.query = value
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input-number {
  width: 100%;
}
.el-form-item {
  .el-button {
    width: 100%;
  }
}
</style>
