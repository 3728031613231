import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 取得常見問題列表
export function getFAQList (params) {
  return request({
    url: '/api/console/faqs',
    method: 'get',
    params: params
  })
}

// 取得單一常見問題
export function getFAQDetail (id) {
  return request({
    url: `/api/console/faqs/${id}`,
    method: 'get'
  })
}

// 狀態變更
export function changeFAQState (id, status) {
  return request({
    url: `/api/console/faqs/${id}`,
    method: 'get',
    params: {
      is_visible: status
    }
  })
}

// 新增FAQ
export function postAddFAQ (data) {
  return request({
    url: '/api/console/faqs',
    method: 'post',
    data: getFormData(data)
  })
}

// 編輯FAQ
export function postEditFAQ (id, data) {
  return request({
    url: `/api/console/faqs/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}

// 刪除FAQ
export function deleteFAQ (id) {
  return request({
    url: `/api/console/faqs/${id}`,
    method: 'delete'
  })
}
